<template>
  <div class="position:relative;">
      <router-link :to="{path: '/login' }" class="btn btn-dark " style="position:absolute; top:20px; right:20px;">Back to Login</router-link>
    <div class="nk-content ">
      <div class="container-fluid">
        <div class="nk-content-inner">
          <div class="nk-content-body">
            <div class="content-page wide-md m-auto">
              <div class="nk-block-head nk-block-head-lg wide-sm mx-auto">
                <div class="nk-block-head-content text-center">
                  <h2 class="nk-block-title fw-normal">Privacy Policy</h2>
                  <div class="nk-block-des">
                    <p class="lead">
                      We take privacy seriously. Contained herein is a comprehensive policy on user data and general privicy related information
                    </p>
                    <p class="text-soft ff-italic">Last Update: 01 April, 2020</p>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-inner card-inner-xl">
                    <div class="entry">
                     
                      <h4>Here is where the information site</h4>
                      <p>
                       i am a paragraph
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created(){
    this.getView()
  },
  methods:{
    getView (){ this.$http.get('/ViewAccess/privacypolicy')
        .then(() => { 
        })
        .catch(() => { 
        })},
  }
};
</script>

<style></style>
